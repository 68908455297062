import { graphql, Link } from "gatsby"
import React from "react"

export default function Blog({ data }) {
	return (
		<div>
			<h1>Blog Roll</h1>
			<ul>
				{data.allMarkdownRemark.edges.map((post) => {
					return (
						<li>
							<Link to={`${post.node.fields.slug}`}>{post.node.frontmatter.title}</Link>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export const pageQuery = graphql`
	query MyQuery {
		allMarkdownRemark {
			edges {
				node {
					id
					frontmatter {
						title
					}
					fields {
						slug
					}
				}
			}
		}
	}
`
